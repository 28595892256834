import React from 'react';
import redHeart from '@assets/svg/RedHeart.svg';
// import grayHeart from '@assets/svg/GrayHeart.svg';
import { UserProfile } from '../LikedProfile.model';
import { NavLink } from 'react-router-dom';
import { addToShortList, removeFromShortList } from '../../../api';

const MembersCard: React.FC<{ profile: UserProfile }> = ({
    profile,
}: {
    profile: UserProfile;
}) => {
    const [profileState, setProfile] = React.useState(profile);

    const likeUnlike = () => {
        // if (profileState.isLiked) {
        if (true) {
            removeFromShortList({
                receiverId: profileState.user_id,
            });
        } else {
            addToShortList({
                receiverId: profileState.user_id,
            });
        }
        // setProfile((oldData) => ({ ...oldData, isLiked: !oldData.isLiked }));
        setProfile((oldData) => ({ ...oldData, isLiked: false }));
    };

    return (
        <div className="grid grid-cols-4 h-fit gap-3 my-history">
            <div className="w-auto bg-bm-gray rounded-lg col-span-1 h-fit">
                <div className="m-2 flex flex-col items-center">
                    <div className="w-11/12  overflow-hidden grid relative card-image">
                        <img
                            src={profileState.profile_image}
                            alt={profileState.first_name}
                            className="justify-self-center pro-img1"
                        />
                        <button
                            type="button"
                            className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center bg-white rounded-full shadow-md hover:bg-gray-100"
                            onClick={likeUnlike}
                        >
                            {/* <img
                                src={
                                    profileState.isLiked ? redHeart : grayHeart
                                }
                                alt="like"
                                className="w-5 h-5"
                            /> */}
                            <img
                                src={redHeart}
                                alt="like"
                                className="w-5 h-5"
                            />
                        </button>
                    </div>
                    <div className=" mob-desc w-100">
                        <div className=" list-mem">
                            <div>Name:</div>
                            <div className="fw-bold">
                                {profileState.first_name}{' '}
                                {profileState.last_name}
                            </div>
                        </div>
                        <div className=" list-mem">
                            <div>Age:</div>
                            <div className="fw-bold">{profileState.age}</div>
                        </div>
                        <div className=" list-mem">
                            <div>Marital Status:</div>
                            <div className="fw-bold">
                                {profileState.maritial_status}
                            </div>
                        </div>
                    </div>
                    <button className="mt-4 w-11/12 bg-gray-400 text-white py-2 rounded-lg">
                        <NavLink to={`/member-profile/${profileState.user_id}`}>
                            View Profile
                        </NavLink>
                    </button>
                </div>
            </div>

            <div className="bg-bm-gray rounded-lg col-span-3 p-7 h-fit mob-des-main">
                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-black">
                        <tbody>
                            <tr className="">
                                <td
                                    scope="row"
                                    className="px-6 py-2 font-medium"
                                >
                                    Name
                                </td>
                                <td className="px-6 py-2">
                                    {profileState.first_name}{' '}
                                    {profileState.last_name}
                                </td>
                                <td className="px-6 py-2 font-medium">
                                    Height
                                </td>
                                <td className="px-6 py-2">
                                    {profileState.height}
                                </td>
                            </tr>
                            <tr className="border-gray-200 dark:border-gray-700">
                                <td
                                    scope="row"
                                    className="px-6 py-2 font-medium"
                                >
                                    Age
                                </td>
                                <td className="px-6 py-2">
                                    {profileState.age}
                                </td>
                                <td className="px-6 py-2 font-medium">
                                    Religion
                                </td>
                                <td className="px-6 py-2">
                                    {profileState.religion}
                                </td>
                            </tr>
                            <tr className="border-gray-200 dark:border-gray-700">
                                <td
                                    scope="row"
                                    className="px-6 py-2 font-medium"
                                >
                                    Qualification
                                </td>
                                <td className="px-6 py-2">
                                    {profileState.qualification}
                                </td>
                                <td className="px-6 py-2 font-medium">Caste</td>
                                <td className="px-6 py-2">
                                    {profileState.caste}
                                </td>
                            </tr>
                            <tr className="border-gray-200 dark:border-gray-700">
                                <td
                                    scope="row"
                                    className="px-6 py-2 font-medium"
                                >
                                    Occupation
                                </td>
                                <td className="px-6 py-2">
                                    {profileState.occupation}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    scope="row"
                                    className="px-6 py-2 font-medium"
                                >
                                    Annual Income
                                </td>
                                <td className="px-6 py-2">
                                    {profileState.annual_income}
                                </td>
                                <td className="px-6 py-2"></td>
                                <td className="px-6 py-2"></td>
                            </tr>
                            <tr>
                                <td
                                    scope="row"
                                    className="px-6 py-2 font-medium"
                                >
                                    Marital Status
                                </td>
                                <td className="px-6 py-2">
                                    {profileState.maritial_status}
                                </td>
                                <td className="px-6 py-2"></td>
                                <td className="px-6 py-2"></td>
                            </tr>
                            <tr>
                                <td
                                    scope="row"
                                    className="px-6 py-2 font-medium"
                                >
                                    Location
                                </td>
                                <td className="px-6 py-2">
                                    {profileState.location}
                                </td>
                                <td className="px-6 py-2"></td>
                                <td className="px-6 py-2"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default MembersCard;
