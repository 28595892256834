import * as React from 'react';
import brideImg from '@assets/images/best-tanks-tops-8 1.png';
import './MyProfile.css';
import {
    fileUpload,
    getCastes,
    getMyProfile,
    getReligions,
    updateProfile,
} from '../../api';
import { useDispatch } from 'react-redux';
import { loadingStarted, loadingStopped } from '@store/IsLoading/reducer';
import { MARITAL_STATUS } from '@constants/MARITAL_STATUS';
import { EMPLOYED_IN } from '@constants/EMPLOYED_IN';
import { EDUCATION_LEVEL } from '@constants/EDUCATION_LEVEL';
import { delay } from '@utils/snippets/delay';

export function ProfilePage() {
    const dispatch = useDispatch();

    const images = [
        brideImg,
        brideImg,
        brideImg,
        brideImg,
        brideImg,
        brideImg,
        brideImg,
    ];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedImage, setSelectedImage] = React.useState<string>(images[0]);

    const [isEditingProfile, setIsEditingProfile] = React.useState(false);
    const [isEditingAbout, setIsEditingAbout] = React.useState(false);
    const [isEditingBasics, setIsEditingBasics] = React.useState(false);
    const [isEditingPreferences, setIsEditingPreferences] =
        React.useState(false);

    const [profile, setProfile] = React.useState<ProfileDTO>();
    const getProfileData = async () => {
        dispatch(loadingStarted(true));
        setProfile(await getMyProfile());
        if (!religion) {
            setReligions(await getReligions());
        }
        dispatch(loadingStopped());
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const updateProfileData = async (profile) => {
        dispatch(loadingStarted(true));
        setProfile(await updateProfile(profile));
        dispatch(loadingStopped());
    };

    const updateProfileDataCallBack = React.useCallback(async () => {
        if (
            isEditingProfile === false ||
            isEditingAbout === false ||
            isEditingBasics === false ||
            isEditingPreferences === false
        ) {
            await updateProfileData(profile);
        }
    }, [
        profile,
        isEditingProfile,
        isEditingAbout,
        isEditingBasics,
        isEditingPreferences,
    ]);
    const [file, setFile] = React.useState(null);

    const updateProfileDataCallBackWithNoCheck = React.useCallback(async () => {
        await updateProfileData(profile);
    }, [profile]);

    const uploadProfileCallBack = React.useCallback(
        async (actualFile) => {
            const formData = new FormData();
            await delay(1000);
            formData.append('PROFILE_IMAGE', actualFile); // 'file' is the key and file is the value

            const response = await fileUpload(formData);
            profile.documents_photos.profile_image = response?.file_name
                ? response?.file_name
                : profile.documents_photos.profile_image;
            setProfile(profile);
            await delay(1000);
            await updateProfileDataCallBackWithNoCheck();
        },
        [profile, file]
    );

    const handleFileChange = async (event) => {
        setFile(event.target.files[0]);
        await delay(1000);
        await uploadProfileCallBack(event.target.files[0]);
    };

    React.useEffect(() => {
        if (!profile) {
            getProfileData();
        }
    });

    const handleInputChange = (e, section, field) => {
        const { value } = e.target;
        setProfile((prevData) => ({
            ...prevData,
            [section]: {
                ...prevData[section],
                [field]: value,
            },
        }));
    };

    const [religion, setReligions] = React.useState<
        Array<{
            _id: string;
            name: string;
        }>
    >();

    const [castes, setCastes] = React.useState<
        Array<{
            _id: string;
            name: string;
        }>
    >([]);

    const fetchCastes = async (id: string) => {
        dispatch(loadingStarted(true));
        const response = await getCastes({ religionId: id });
        setCastes(response);
        dispatch(loadingStopped());
    };

    return (
        <>
            {profile ? (
                <>
                    <div className="flex overflow-hidden flex-col bg-white pb-10">
                        <div className="flex flex-col items-start self-center mt-4 w-full max-w-[1400px] max-md:mt-10 max-md:max-w-full">
                            <div className="max-w-full w-[1000px]">
                                <div className="flex gap-4 max-md:flex-col">
                                    <div className="flex flex-col w-4/12 max-md:ml-0 max-md:w-full">
                                        <div className="flex flex-col grow justify-center px-3 py-1 w-full bg-gray-200 rounded-xl max-md:pr-5 max-md:mt-8 max-md:max-w-full">
                                            <img
                                                loading="lazy"
                                                src={selectedImage}
                                                className="object-contain w-full aspect-[0.68] max-md:max-w-full"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col ml-3 w-8/12  max-md:ml-0 max-md:w-full">
                                        <div className="flex flex-col items-start mt-4 w-full text-lg text-red-950 max-md:mt-8 max-md:max-w-full">
                                            <div className="flex gap-2 max-w-full ">
                                                <div className="flex flex-col font-bold">
                                                    <div
                                                        className=""
                                                        style={{
                                                            minWidth: '200px',
                                                        }}
                                                    >
                                                        NAME:{' '}
                                                    </div>
                                                    <div
                                                        className="self-start mt-4 max-md:mt-6 "
                                                        style={{
                                                            minWidth: '200px',
                                                        }}
                                                    >
                                                        AGE:
                                                    </div>
                                                </div>
                                                <div
                                                    className="flex flex-col font-medium ml-2"
                                                    style={{
                                                        minWidth: '200px',
                                                    }}
                                                >
                                                    <div className="self-start">
                                                        {isEditingProfile ? (
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    value={
                                                                        profile
                                                                            ?.basic_info
                                                                            ?.first_name
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleInputChange(
                                                                            e,
                                                                            'basic_info',
                                                                            'first_name'
                                                                        )
                                                                    }
                                                                    className="border rounded px-2 py-1"
                                                                />
                                                                <input
                                                                    type="text"
                                                                    value={
                                                                        profile
                                                                            ?.basic_info
                                                                            ?.last_name
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleInputChange(
                                                                            e,
                                                                            'basic_info',
                                                                            'last_name'
                                                                        )
                                                                    }
                                                                    className="border rounded px-2 py-1"
                                                                />
                                                            </>
                                                        ) : (
                                                            `${profile?.basic_info?.first_name} ${profile?.basic_info?.last_name}`
                                                        )}
                                                    </div>
                                                    <div className="self-start mt-4 max-md:mt-6">
                                                        {isEditingProfile ? (
                                                            <input
                                                                type="number"
                                                                value={
                                                                    profile
                                                                        ?.basic_info
                                                                        ?.age
                                                                }
                                                                onChange={(e) =>
                                                                    handleInputChange(
                                                                        e,
                                                                        'basic_info',
                                                                        'age'
                                                                    )
                                                                }
                                                                className="border rounded px-2 py-1"
                                                            />
                                                        ) : (
                                                            profile?.basic_info
                                                                ?.age
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex gap-4 mt-4 whitespace-nowrap max-md:mt-6">
                                                <div
                                                    className="grow font-bold "
                                                    style={{
                                                        minWidth: '200px',
                                                    }}
                                                >
                                                    LOCATION:
                                                </div>
                                                <div className="font-medium">
                                                    {isEditingProfile ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.basic_info
                                                                    ?.address
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'basic_info',
                                                                    'address'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile?.basic_info
                                                            ?.address
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex gap-4 mt-4 whitespace-nowrap max-md:mt-6">
                                                <div
                                                    className="font-bold basis-auto "
                                                    style={{
                                                        minWidth: '200px',
                                                    }}
                                                >
                                                    RELIGION:
                                                </div>
                                                <div className="font-medium basis-auto">
                                                    {isEditingProfile ? (
                                                        <select
                                                            className="border rounded px-2 py-1"
                                                            name="religion"
                                                            value={
                                                                profile
                                                                    ?.religious_social_background
                                                                    ?.religion
                                                            }
                                                            onChange={(e) => {
                                                                handleInputChange(
                                                                    e,
                                                                    'religious_social_background',
                                                                    'religion'
                                                                );
                                                                fetchCastes(
                                                                    e?.target
                                                                        ?.value
                                                                );
                                                            }}
                                                        >
                                                            <option
                                                                value={null}
                                                            >
                                                                Religion
                                                            </option>
                                                            {religion?.map(
                                                                (item) => (
                                                                    <option
                                                                        key={
                                                                            item._id
                                                                        }
                                                                        value={
                                                                            item.name
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    ) : (
                                                        profile
                                                            ?.religious_social_background
                                                            ?.religion
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex gap-2 mt-4 whitespace-nowrap max-md:mt-6">
                                                <div
                                                    className="grow font-bold "
                                                    style={{
                                                        minWidth: '200px',
                                                    }}
                                                >
                                                    HEIGHT:
                                                </div>
                                                <div className="font-medium ml-2">
                                                    {isEditingProfile ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.physical_attributes
                                                                    ?.height
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'physical_attributes',
                                                                    'height'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile
                                                            ?.physical_attributes
                                                            ?.height
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex gap-2 mt-4 whitespace-nowrap max-md:mt-6">
                                                <div
                                                    className="grow font-bold "
                                                    style={{
                                                        minWidth: '200px',
                                                    }}
                                                >
                                                    Marital Status:
                                                </div>
                                                <div className="font-medium ml-2">
                                                    {isEditingProfile ? (
                                                        <select
                                                            className="border rounded px-2 py-1"
                                                            name="maritial_status"
                                                            value={
                                                                profile
                                                                    ?.basic_info
                                                                    ?.maritial_status
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'basic_info',
                                                                    'maritial_status'
                                                                )
                                                            }
                                                        >
                                                            <option
                                                                value={null}
                                                            >
                                                                Select
                                                            </option>
                                                            {MARITAL_STATUS.map(
                                                                (item) => (
                                                                    <option
                                                                        key={
                                                                            item.key
                                                                        }
                                                                        value={
                                                                            item.value
                                                                        }
                                                                    >
                                                                        {
                                                                            item.key
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    ) : (
                                                        profile?.basic_info
                                                            ?.maritial_status
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex gap-2 mt-4 whitespace-nowrap max-md:mt-6">
                                                <div
                                                    className="grow font-bold "
                                                    style={{
                                                        minWidth: '200px',
                                                    }}
                                                >
                                                    Hobbies & Interest:
                                                </div>
                                                <div className="font-medium ml-2">
                                                    {isEditingProfile ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.basic_info
                                                                    ?.hobbies_intrest
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'basic_info',
                                                                    'hobbies_intrest'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile?.basic_info
                                                            ?.hobbies_intrest
                                                    )}
                                                </div>
                                            </div>
                                            <button
                                                onClick={() => {
                                                    setIsEditingProfile(
                                                        !isEditingProfile
                                                    );
                                                    delay(1000);
                                                    updateProfileDataCallBack();
                                                }}
                                                className="mt-4 bg-orange-500 text-white px-4 py-2 rounded"
                                            >
                                                {isEditingProfile
                                                    ? 'Save Profile'
                                                    : 'Edit Profile'}
                                            </button>
                                            <input
                                                type="file"
                                                title="Upload Profile"
                                                name="profile"
                                                id="profileUpload"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* About Section */}
                            <div className="flex flex-col items-start mt-8 w-full max-w-[1400px] max-md:max-w-full">
                                <div className="flex flex-col w-full bg-gray-200 rounded-xl px-5 py-4">
                                    <h2 className="text-xl font-bold mb-4">
                                        ABOUT
                                    </h2>
                                    {isEditingAbout ? (
                                        <textarea
                                            value={
                                                profile?.basic_info?.about_me
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    'basic_info',
                                                    'about_me'
                                                )
                                            }
                                            className="border  text-black rounded px-2 py-1 w-full"
                                        />
                                    ) : (
                                        <p className="text-justify">
                                            {profile?.basic_info?.about_me}
                                        </p>
                                    )}
                                    <button
                                        onClick={() => {
                                            setIsEditingAbout(!isEditingAbout);
                                            delay(1000);
                                            updateProfileDataCallBack();
                                        }}
                                        className="mt-4 bg-orange-500 text-white px-4 py-2 rounded"
                                    >
                                        {isEditingAbout
                                            ? 'Save About'
                                            : 'Edit About'}
                                    </button>
                                </div>
                            </div>

                            {/* Basics & Lifestyle Section starts */}
                            <div className="flex flex-col items-start mt-8 w-full max-w-[1400px] max-md:max-w-full">
                                <div className="flex flex-col w-full bg-gray-200 rounded-xl px-5 py-4">
                                    <h2 className="text-xl font-bold mb-4">
                                        BASICS & LIFESTYLE
                                    </h2>
                                    <div className="flex flex-col gap-2">
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Drinks:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.physical_attributes
                                                                ?.drink
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'physical_attributes',
                                                                'drink'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.physical_attributes
                                                        ?.drink
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Height:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.physical_attributes
                                                                ?.height
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'physical_attributes',
                                                                'height'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.physical_attributes
                                                        ?.height
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Weight:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.physical_attributes
                                                                ?.weight
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'physical_attributes',
                                                                'weight'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.physical_attributes
                                                        ?.weight
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Gender:
                                            </div>
                                            <div>
                                                {
                                                    profile?.physical_attributes
                                                        ?.gender
                                                }
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Blood Group:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.physical_attributes
                                                                ?.blood_group
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'physical_attributes',
                                                                'blood_group'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.physical_attributes
                                                        ?.blood_group
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Complexion:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.physical_attributes
                                                                ?.complexion
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'physical_attributes',
                                                                'complexion'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.physical_attributes
                                                        ?.complexion
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Body Type:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.physical_attributes
                                                                ?.body_type
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'physical_attributes',
                                                                'body_type'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.physical_attributes
                                                        ?.body_type
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Spectacles:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.physical_attributes
                                                                ?.spectacles
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'physical_attributes',
                                                                'spectacles'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.physical_attributes
                                                        ?.spectacles
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Smoking:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.physical_attributes
                                                                ?.smoke
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'physical_attributes',
                                                                'smoke'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.physical_attributes
                                                        ?.smoke
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Diet:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.physical_attributes
                                                                ?.diet
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'physical_attributes',
                                                                'diet'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.physical_attributes
                                                        ?.diet
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Physical Status:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.physical_attributes
                                                                ?.physical_status
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'physical_attributes',
                                                                'physical_status'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.physical_attributes
                                                        ?.physical_status
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                disabled_reason:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.physical_attributes
                                                                ?.disabled_reason
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'physical_attributes',
                                                                'disabled_reason'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.physical_attributes
                                                        ?.disabled_reason
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => {
                                            setIsEditingBasics(
                                                !isEditingBasics
                                            );
                                            delay(1000);
                                            updateProfileDataCallBack();
                                        }}
                                        className="mt-4 bg-orange-500 text-white px-4 py-2 rounded"
                                    >
                                        {isEditingBasics
                                            ? 'Save Basics & Lifestyle'
                                            : 'Edit Basics & Lifestyle'}
                                    </button>
                                </div>
                            </div>
                            {/* Basics & Lifestyle Section ends */}
                            {/* Astro details Section Starts*/}
                            <div className="flex flex-col items-start mt-8 w-full max-w-[1400px] max-md:max-w-full">
                                <div className="flex flex-col w-full bg-gray-200 rounded-xl px-5 py-4">
                                    <h2 className="text-xl font-bold mb-4">
                                        Astro Details
                                    </h2>
                                    <div className="flex flex-col gap-2">
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Date of Birth:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.astro_details
                                                                ?.date_of_birth
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'astro_details',
                                                                'date_of_birth'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.astro_details
                                                        ?.date_of_birth
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Birth Time:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.astro_details
                                                                ?.birth_time
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'astro_details',
                                                                'birth_time'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.astro_details
                                                        ?.birth_time
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Place of Birth:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.astro_details
                                                                ?.place_of_birth
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'astro_details',
                                                                'place_of_birth'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.astro_details
                                                        ?.place_of_birth
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Rashi:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.astro_details
                                                                ?.rashi
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'astro_details',
                                                                'rashi'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.astro_details
                                                        ?.rashi
                                                )}
                                            </div>
                                        </div>

                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Nakshatra:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.astro_details
                                                                ?.nakshatra
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'astro_details',
                                                                'nakshatra'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.astro_details
                                                        ?.nakshatra
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Gotra:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.astro_details
                                                                ?.gotra
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'astro_details',
                                                                'gotra'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.astro_details
                                                        ?.gotra
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Manglik:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <select
                                                        className="border rounded px-2 py-1"
                                                        name="mangal"
                                                        value={
                                                            profile
                                                                ?.astro_details
                                                                ?.is_manglik
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'astro_details',
                                                                'is_manglik'
                                                            )
                                                        }
                                                    >
                                                        <option value="">
                                                            Select
                                                        </option>
                                                        <option value="yes">
                                                            Yes
                                                        </option>
                                                        <option value="no">
                                                            No
                                                        </option>
                                                    </select>
                                                ) : (
                                                    profile?.astro_details
                                                        ?.is_manglik
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => {
                                            setIsEditingBasics(
                                                !isEditingBasics
                                            );
                                            delay(1000);
                                            updateProfileDataCallBack();
                                        }}
                                        className="mt-4 bg-orange-500 text-white px-4 py-2 rounded"
                                    >
                                        {isEditingBasics
                                            ? 'Save Astro Details'
                                            : 'Edit Astro Details'}
                                    </button>
                                </div>
                            </div>
                            {/* Astro Section Ends Here*/}
                            {/* Education Occuption Section starts */}
                            <div className="flex flex-col items-start mt-8 w-full max-w-[1400px] max-md:max-w-full">
                                <div className="flex flex-col w-full bg-gray-200 rounded-xl px-5 py-4">
                                    <h2 className="text-xl font-bold mb-4">
                                        Education Occupation
                                    </h2>
                                    <div className="flex flex-col gap-2">
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Qualification:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <select
                                                        name="education"
                                                        value={
                                                            profile
                                                                ?.education_occupation
                                                                ?.qualification
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'education_occupation',
                                                                'qualification'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    >
                                                        <option value="">
                                                            Select
                                                        </option>
                                                        {EDUCATION_LEVEL.map(
                                                            (item) => (
                                                                <option
                                                                    key={
                                                                        item.key
                                                                    }
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.value}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                ) : (
                                                    profile
                                                        ?.education_occupation
                                                        ?.qualification
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Occupation:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.education_occupation
                                                                ?.occupation
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'education_occupation',
                                                                'occupation'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile
                                                        ?.education_occupation
                                                        ?.occupation
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Annual Income:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.education_occupation
                                                                ?.annual_income
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'education_occupation',
                                                                'annual_income'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile
                                                        ?.education_occupation
                                                        ?.annual_income
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Employed In:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <select
                                                        value={
                                                            profile
                                                                ?.education_occupation
                                                                ?.employed_in
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'education_occupation',
                                                                'employed_in'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    >
                                                        <option value="">
                                                            Select
                                                        </option>
                                                        {EMPLOYED_IN.map(
                                                            (item) => (
                                                                <option
                                                                    key={
                                                                        item.key
                                                                    }
                                                                    value={
                                                                        item.value
                                                                    }
                                                                >
                                                                    {item.value}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                ) : (
                                                    profile
                                                        ?.education_occupation
                                                        ?.employed_in
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                working_with_company:
                                            </div>
                                            <div>
                                                {isEditingBasics ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.education_occupation
                                                                ?.working_with_company
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'education_occupation',
                                                                'working_with_company'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile
                                                        ?.education_occupation
                                                        ?.working_with_company
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => {
                                            setIsEditingBasics(
                                                !isEditingBasics
                                            );
                                            delay(1000);
                                            updateProfileDataCallBack();
                                        }}
                                        className="mt-4 bg-orange-500 text-white px-4 py-2 rounded"
                                    >
                                        {isEditingBasics
                                            ? 'Save Education & Occuption'
                                            : 'Edit Education & Occuption'}
                                    </button>
                                </div>
                            </div>
                            {/* Education Occuption Section ends */}
                            {/* Partner Preference Section starts*/}
                            <div className="flex flex-col items-start mt-8 w-full max-w-[1400px] max-md:max-w-full">
                                <div className="flex flex-col w-full bg-gray-200 rounded-xl px-5 py-4">
                                    <h2 className="text-xl font-bold mb-4">
                                        PARTNER PREFERENCE
                                    </h2>
                                    <div className="flex flex-col gap-2">
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Height:
                                            </div>
                                            <div>
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="number"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.height.from
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'height.from'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.height?.from
                                                )}
                                                -
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.height?.to
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'height.to'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.height?.to
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Weight:
                                            </div>
                                            <div>
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.weight?.from
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'weight.from'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.weight?.from
                                                )}
                                                -
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.weight?.to
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'weight.to'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.weight?.to
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Age:
                                            </div>
                                            <div>
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="number"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.age?.from
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'age.from'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.age?.from
                                                )}
                                                -
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="number"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.age?.to
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'age.to'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.age?.to
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Qualification:
                                            </div>
                                            <div>
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="number"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.qualification
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'qualification'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.qualification
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Maritial Status:
                                            </div>
                                            <div>
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="number"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.maritial_status
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'maritial_status'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.maritial_status
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Salary Expectation:
                                            </div>
                                            <div>
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.salary_expectation
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'salary_expectation'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.salary_expectation
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Children:
                                            </div>
                                            <div>
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.have_children
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'have_children'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.have_children
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Intercaste:
                                            </div>
                                            <div>
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.intercast
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'intercast'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.intercast
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Salary Expectation:
                                            </div>
                                            <div>
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.salary_expectation
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'salary_expectation'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.salary_expectation
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Body Type:
                                            </div>
                                            <div>
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.body_type
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'body_type'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.body_type
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Complexion:
                                            </div>
                                            <div>
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.complexion
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'complexion'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.complexion
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Mangal:
                                            </div>
                                            <div>
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.is_manglik
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'is_manglik'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.is_manglik
                                                )}
                                            </div>
                                        </div>

                                        <div className="flex justify-between">
                                            <div className="font-bold">
                                                Partner Expectations:
                                            </div>
                                            <div>
                                                {isEditingPreferences ? (
                                                    <input
                                                        type="text"
                                                        value={
                                                            profile
                                                                ?.partner_preference
                                                                ?.partner_expectaion_details
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                'partner_preference',
                                                                'partner_expectaion_details'
                                                            )
                                                        }
                                                        className="border rounded px-2 py-1"
                                                    />
                                                ) : (
                                                    profile?.partner_preference
                                                        ?.partner_expectaion_details
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => {
                                            setIsEditingPreferences(
                                                !isEditingPreferences
                                            );
                                            delay(1000);
                                            updateProfileDataCallBack();
                                        }}
                                        className="mt-4 bg-orange-500 text-white px-4 py-2 rounded"
                                    >
                                        {isEditingPreferences
                                            ? 'Save Partner Preferences'
                                            : 'Edit Partner Preferences'}
                                    </button>
                                </div>
                                {/* Family Section Starts*/}
                                <div className="flex flex-col items-start mt-8 w-full max-w-[1400px] max-md:max-w-full">
                                    <div className="flex flex-col w-full bg-gray-200 rounded-xl px-5 py-4">
                                        <h2 className="text-xl font-bold mb-4">
                                            FAMILY
                                        </h2>
                                        <div className="flex flex-col gap-2">
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Father's Name:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.family_details
                                                                    ?.father_name
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'family_details',
                                                                    'father_name'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile?.family_details
                                                            ?.father_name
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Father Occupation:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.family_details
                                                                    ?.father_occupation
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'family_details',
                                                                    'father_occupation'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile?.family_details
                                                            ?.father_occupation
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Mother Name:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.family_details
                                                                    ?.mother_name
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'family_details',
                                                                    'mother_name'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile?.family_details
                                                            ?.mother_name
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Mother Occupation:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.family_details
                                                                    ?.mother_occupation
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'family_details',
                                                                    'mother_occupation'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile?.family_details
                                                            ?.mother_occupation
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Brothers:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.family_details
                                                                    ?.no_of_brothers
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'family_details',
                                                                    'no_of_brothers'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile?.family_details
                                                            ?.no_of_brothers
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Married Brothers:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.family_details
                                                                    ?.married_brothers
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'family_details',
                                                                    'married_brothers'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile?.family_details
                                                            ?.married_brothers
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Sisters:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.family_details
                                                                    ?.no_of_sisters
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'family_details',
                                                                    'no_of_sisters'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile?.family_details
                                                            ?.no_of_sisters
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Married Sisters:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.family_details
                                                                    ?.married_sisters
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'family_details',
                                                                    'married_sisters'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile?.family_details
                                                            ?.married_sisters
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            onClick={() => {
                                                setIsEditingBasics(
                                                    !isEditingBasics
                                                );
                                                delay(1000);
                                                updateProfileDataCallBack();
                                            }}
                                            className="mt-4 bg-orange-500 text-white px-4 py-2 rounded"
                                        >
                                            {isEditingBasics
                                                ? 'Save Family Details'
                                                : 'Edit Family Details'}
                                        </button>
                                    </div>
                                </div>
                                {/* Family Section Ends Here*/}
                                {/* Religious Social Bacckgroud Section Starts*/}
                                <div className="flex flex-col items-start mt-8 w-full max-w-[1400px] max-md:max-w-full">
                                    <div className="flex flex-col w-full bg-gray-200 rounded-xl px-5 py-4">
                                        <h2 className="text-xl font-bold mb-4">
                                            Religious Social Background
                                        </h2>
                                        <div className="flex flex-col gap-2">
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Religion:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <select
                                                            className="border rounded px-2 py-1"
                                                            name="religion"
                                                            value={
                                                                profile
                                                                    ?.religious_social_background
                                                                    ?.religion
                                                            }
                                                            onChange={(e) => {
                                                                handleInputChange(
                                                                    e,
                                                                    'religious_social_background',
                                                                    'religion'
                                                                );
                                                                fetchCastes(
                                                                    e?.target
                                                                        ?.value
                                                                );
                                                            }}
                                                        >
                                                            <option
                                                                value={null}
                                                            >
                                                                Religion
                                                            </option>
                                                            {religion?.map(
                                                                (item) => (
                                                                    <option
                                                                        key={
                                                                            item._id
                                                                        }
                                                                        value={
                                                                            item.name
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    ) : (
                                                        profile
                                                            ?.religious_social_background
                                                            ?.religion
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Cast:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <select
                                                            value={
                                                                profile
                                                                    ?.religious_social_background
                                                                    ?.caste
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'religious_social_background',
                                                                    'caste'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        >
                                                            <option
                                                                value={null}
                                                            >
                                                                Caste
                                                            </option>
                                                            {castes?.map(
                                                                (item) => (
                                                                    <option
                                                                        key={
                                                                            item._id
                                                                        }
                                                                        value={
                                                                            item.name
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    ) : (
                                                        profile
                                                            ?.religious_social_background
                                                            ?.caste
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Mother Tongue:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.religious_social_background
                                                                    ?.mother_tongue
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'religious_social_background',
                                                                    'mother_tongue'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile
                                                            ?.religious_social_background
                                                            ?.mother_tongue
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Family Status:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.religious_social_background
                                                                    ?.family_status
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'religious_social_background',
                                                                    'family_status'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile
                                                            ?.religious_social_background
                                                            ?.family_status
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Family Type:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.religious_social_background
                                                                    ?.family_type
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'religious_social_background',
                                                                    'family_type'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile
                                                            ?.religious_social_background
                                                            ?.family_type
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            onClick={() => {
                                                setIsEditingBasics(
                                                    !isEditingBasics
                                                );
                                                delay(1000);
                                                updateProfileDataCallBack();
                                            }}
                                            className="mt-4 bg-orange-500 text-white px-4 py-2 rounded"
                                        >
                                            {isEditingBasics
                                                ? 'Save Religious & Social Bacckgroud'
                                                : 'Edit Religious & Social Bacckgroud'}
                                        </button>
                                    </div>
                                </div>
                                {/* Religious Social Bacckgroud Ends Here*/}
                                {/* resedence_details Section Starts*/}
                                <div className="flex flex-col items-start mt-8 w-full max-w-[1400px] max-md:max-w-full">
                                    <div className="flex flex-col w-full bg-gray-200 rounded-xl px-5 py-4">
                                        <h2 className="text-xl font-bold mb-4">
                                            Residence Details
                                        </h2>
                                        <div className="flex flex-col gap-2">
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Current Residence:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.resedence_details
                                                                    ?.current_residence
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'resedence_details',
                                                                    'current_residence'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile
                                                            ?.resedence_details
                                                            ?.current_residence
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Full Address:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.resedence_details
                                                                    ?.full_address
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'resedence_details',
                                                                    'full_address'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile
                                                            ?.resedence_details
                                                            ?.full_address
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Pin Code:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.resedence_details
                                                                    ?.pin_code
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'resedence_details',
                                                                    'pin_code'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile
                                                            ?.resedence_details
                                                            ?.pin_code
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Country:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.resedence_details
                                                                    ?.country
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'resedence_details',
                                                                    'country'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile
                                                            ?.resedence_details
                                                            ?.country
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    State:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.resedence_details
                                                                    ?.state
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'resedence_details',
                                                                    'state'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile
                                                            ?.resedence_details
                                                            ?.state
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    District:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.resedence_details
                                                                    ?.district
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'resedence_details',
                                                                    'district'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile
                                                            ?.resedence_details
                                                            ?.district
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    City:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.resedence_details
                                                                    ?.city
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'resedence_details',
                                                                    'city'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile
                                                            ?.resedence_details
                                                            ?.city
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="font-bold">
                                                    Residence Status:
                                                </div>
                                                <div>
                                                    {isEditingBasics ? (
                                                        <input
                                                            type="text"
                                                            value={
                                                                profile
                                                                    ?.resedence_details
                                                                    ?.residence_status
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    'resedence_details',
                                                                    'residence_status'
                                                                )
                                                            }
                                                            className="border rounded px-2 py-1"
                                                        />
                                                    ) : (
                                                        profile
                                                            ?.resedence_details
                                                            ?.residence_status
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            onClick={() => {
                                                setIsEditingBasics(
                                                    !isEditingBasics
                                                );
                                                delay(1000);
                                                updateProfileDataCallBack();
                                            }}
                                            className="mt-4 bg-orange-500 text-white px-4 py-2 rounded"
                                        >
                                            {isEditingBasics
                                                ? 'Save Residence Details'
                                                : 'Edit Residence Details'}
                                        </button>
                                    </div>
                                </div>
                                {/* resedence_details Ends Here*/}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                ''
            )}
        </>
    );
}
interface AstroDetails {
    date_of_birth: string;
    birth_time: string;
    place_of_birth: string;
    rashi: string;
    name_as_rashi: string;
    nakshatra: string;
    gotra: string;
    is_manglik: string;
}

interface BasicInfo {
    first_name: string;
    last_name: string;
    age: number;
    address: string;
    maritial_status: string;
    have_children: string;
    no_of_children: string;
    hobbies_intrest: string;
    about_me: string;
    _id: string;
}

interface DocumentsPhotos {
    profile_image: string;
    astro_profile: string;
    govt_document: string;
    _id: string;
}

interface EducationOccupation {
    qualification: string;
    occupation: string;
    annual_income: string;
    employed_in: string;
    working_with_company: string;
}

interface FamilyDetails {
    father_name: string;
    father_occupation: string;
    mother_name: string;
    mother_occupation: string;
    no_of_brothers: string;
    married_brothers: string;
    no_of_sisters: string;
    married_sisters: string;
    _id: string;
}

interface HeightRange {
    from: number;
    to: number;
    _id: string;
}

interface WeightRange {
    from: number;
    to: number;
    _id: string;
}

interface AgeRange {
    from: number;
    to: number;
    _id: string;
}

interface PartnerPreference {
    height: HeightRange;
    weight: WeightRange;
    age: AgeRange;
    qualification: string;
    salary_expectation: string;
    maritial_status: string;
    have_children: string;
    body_type: string;
    complexion: string;
    intercast: string;
    is_manglik: string;
    partner_expectaion_details: string;
    _id: string;
}

interface PhysicalAttributes {
    height: number;
    weight: number;
    gender: string;
    blood_group: string;
    complexion: string;
    body_type: string;
    spectacles: string;
    smoke: string;
    drink: string;
    diet: string;
    physical_status: string;
    disabled_reason: string;
    _id: string;
}

interface ReligiousSocialBackground {
    religion: string;
    caste: string;
    caste_category: string;
    mother_tongue: string;
    family_status: string;
    family_type: string;
    _id: string;
}

interface ResidenceDetails {
    current_residence: string;
    full_address: string;
    pin_code: string;
    country: string;
    state: string;
    district: string;
    city: string;
    residence_status: string;
    _id: string;
}

interface ProfileDTO {
    _id: string;
    astro_details: AstroDetails;
    basic_info: BasicInfo;
    documents_photos: DocumentsPhotos;
    education_occupation: EducationOccupation;
    family_details: FamilyDetails;
    partner_preference: PartnerPreference;
    physical_attributes: PhysicalAttributes;
    religious_social_background: ReligiousSocialBackground;
    resedence_details: ResidenceDetails;
}

// interface ApiResponse {
//     status: string;
//     data: Profile;
// }
