import axios from 'axios';
import {
    LoginRequest,
    RegisterRequest,
    ForgetPasswordRequest,
    VerifyOtpRequest,
    ResetPasswordRequest,
    GenerateOrder,
    GenerateOrderResponse,
    SearchRequest,
    SubscriptionPlan,
    VerifySignatureRequest,
    ContactUsRequest,
    ChangePasswordRequest,
} from './models';
import { MapUserProfilesToProfiles } from './mapper';

// Example usage

const loginURL = `${process.env['REACT_APP_API_ROOT_URL']}user/login`;
const getMemberSearchListURL = `${process.env['REACT_APP_API_ROOT_URL']}user/members`;
const updateProfileURL = `${process.env['REACT_APP_API_ROOT_URL']}user/update-profile`;
const fileUploadURL = `${process.env['REACT_APP_API_ROOT_URL']}common/upload-files`;
const getPlansURL = `${process.env['REACT_APP_API_ROOT_URL']}plans/get-plans`;
const generateOrderURL = `${process.env['REACT_APP_API_ROOT_URL']}payment/generate-order`;
const verifySignatureURL = `${process.env['REACT_APP_API_ROOT_URL']}payment/verify-signature`;
const getShortListedUserURL = `${process.env['REACT_APP_API_ROOT_URL']}user/get-shortlist-users`;
const getReligionURL = `${process.env['REACT_APP_API_ROOT_URL']}common/get-religions`;
const getCastesURL = `${process.env['REACT_APP_API_ROOT_URL']}common/get-caste`;
const getProfileURL = `${process.env['REACT_APP_API_ROOT_URL']}user/get-my-profile`;
const getMembersProfileURL = `${process.env['REACT_APP_API_ROOT_URL']}user/get-member-profile`;
const viewContactURL = `${process.env['REACT_APP_API_ROOT_URL']}user/viewed-contact`;
const getViewHistoryURL = `${process.env['REACT_APP_API_ROOT_URL']}user/my-viewed-history`;
const addToShortListURL = `${process.env['REACT_APP_API_ROOT_URL']}user/add-shortlist`;
const removeFromShortListURL = `${process.env['REACT_APP_API_ROOT_URL']}user/remove-shortlist`;
const registerUrl = `${process.env['REACT_APP_API_ROOT_URL']}user/register`;
const forgetPasswordUrl = `${process.env['REACT_APP_API_ROOT_URL']}user/forgot-password`;
const verifyOtpUrl = `${process.env['REACT_APP_API_ROOT_URL']}user/verify-otp`;
const resetPasswordUrl = `${process.env['REACT_APP_API_ROOT_URL']}user/update-password`;
const contactUsUrl = `${process.env['REACT_APP_API_ROOT_URL']}common/submit-contactus-form`;
const changePasswordUrl = `${process.env['REACT_APP_API_ROOT_URL']}user/change-password`;

const defaultHeaders = {
    'Content-Type': 'application/json',
    Authorization: process.env['REACT_APP_API_TOKEN'],
};

function GetDefaultHeaders() {
    if (localStorage.getItem('token')) {
        return {
            ...defaultHeaders,
            Authorization: localStorage.getItem('token'),
        };
    }

    return defaultHeaders;
}

export async function postSearchRequest(
    payload: SearchRequest,
    url: string = getMemberSearchListURL
) {
    try {
        const response = await axios.post(url, payload, {
            headers: GetDefaultHeaders(),
        });

        // console.log('Response:', response.data);

        return MapUserProfilesToProfiles(response.data.data);
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function updateProfile(
    payload: unknown,
    url: string = updateProfileURL
) {
    try {
        const response = await axios.post(url, payload, {
            headers: GetDefaultHeaders(),
        });

        // console.log('Response:', response.data);

        return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function fileUpload(
    payload: unknown,
    url: string = fileUploadURL
) {
    try {
        const response = await axios.post(url, payload, {
            headers: {
                ...GetDefaultHeaders(),
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function getShortListedUser(
    payload: { pageSize: number; pageNumber: number },
    url: string = getShortListedUserURL
) {
    try {
        const response = await axios.get(
            `${url}?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`,
            {
                headers: GetDefaultHeaders(),
            }
        );

        if (response.data.data) {
            return response.data.data;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function getReligions(url: string = getReligionURL) {
    try {
        const response = await axios.get(`${url}`, {
            headers: GetDefaultHeaders(),
        });

        if (response.data.data) {
            return response.data.data;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function getCastes(
    payload: { religionId: string },
    url: string = getCastesURL
) {
    try {
        const response = await axios.get(
            `${url}?religion=${payload.religionId}`,
            {
                headers: GetDefaultHeaders(),
            }
        );

        if (response.data.data) {
            return response.data.data;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function connectRequest(
    userId: string,
    url: string = viewContactURL
) {
    try {
        const response = await axios.post(
            `${url}`,
            { memberId: userId },
            {
                headers: GetDefaultHeaders(),
            }
        );

        if (response.data.data) {
            return response.data.data;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function getMembersProfile(
    userId: string,
    url: string = getMembersProfileURL
) {
    try {
        const response = await axios.get(`${url}?user_id=${userId}`, {
            headers: GetDefaultHeaders(),
        });

        if (response.data.data) {
            return response.data.data;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function getMyProfile(url: string = getProfileURL) {
    try {
        const response = await axios.get(`${url}`, {
            headers: GetDefaultHeaders(),
        });

        if (response.data.data) {
            return response.data.data;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function getPlans(url: string = getPlansURL) {
    try {
        const response = await axios.get(`${url}`, {
            headers: GetDefaultHeaders(),
        });

        if (response.data?.data) {
            return JSON.parse(
                JSON.stringify(response.data.data)
            ) as Array<SubscriptionPlan>;
        } else {
            return [] as Array<SubscriptionPlan>;
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function createOrder(
    payload: GenerateOrder,
    url: string = generateOrderURL
) {
    try {
        const response = await axios.post(`${url}`, payload, {
            headers: GetDefaultHeaders(),
        });

        if (response.data) {
            return JSON.parse(
                JSON.stringify(response.data)
            ) as GenerateOrderResponse;
        } else {
            return {} as GenerateOrderResponse;
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function verifySignature(
    payload: VerifySignatureRequest,
    url: string = verifySignatureURL
) {
    try {
        const response = await axios.post(`${url}`, payload, {
            headers: GetDefaultHeaders(),
        });

        if (response.data) {
            return JSON.parse(
                JSON.stringify(response.data)
            ) as GenerateOrderResponse;
        } else {
            return {} as GenerateOrderResponse;
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function getViewHistory(
    payload: { pageSize: number; pageNumber: number },
    url: string = getViewHistoryURL
) {
    try {
        const response = await axios.get(
            `${url}?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`,
            {
                headers: GetDefaultHeaders(),
            }
        );

        if (response.data) {
            return response.data;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function addToShortList(
    payload: { receiverId: string },
    url: string = addToShortListURL
): Promise<boolean> {
    try {
        const response = await axios.post(url, payload, {
            headers: GetDefaultHeaders(),
        });

        return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function removeFromShortList(
    payload: { receiverId: string },
    url: string = removeFromShortListURL
): Promise<boolean> {
    try {
        const response = await axios.post(url, payload, {
            headers: GetDefaultHeaders(),
        });

        return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function loginUserRequest(
    payload: LoginRequest,
    url: string = loginURL
) {
    try {
        const response = await axios.post(url, payload, {
            headers: GetDefaultHeaders(),
        });

        if (response.data.token) {
            return response.data;
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function registerUserRequest(
    payload: RegisterRequest,
    url: string = registerUrl
) {
    try {
        const res = await axios.post(url, payload, {
            headers: GetDefaultHeaders(),
        });
        return res?.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function ForgetPasswordUserRequest(
    payload: ForgetPasswordRequest,
    url: string = forgetPasswordUrl
) {
    try {
        await axios.post(url, payload, {
            headers: GetDefaultHeaders(),
        });
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function VerifyOtpUserRequest(
    payload: VerifyOtpRequest,
    url: string = verifyOtpUrl
) {
    try {
        await axios.post(url, payload, {
            headers: GetDefaultHeaders(),
        });
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function ResetPasswordUserRequest(
    payload: ResetPasswordRequest,
    url: string = resetPasswordUrl
) {
    try {
        await axios.post(url, payload, {
            headers: GetDefaultHeaders(),
        });
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function ContactUsUserRequest(
    payload: ContactUsRequest,
    url: string = contactUsUrl
) {
    try {
        const response = await axios.post(url, payload, {
            headers: GetDefaultHeaders(),
        });

        console.log('Response:', response.data.data);
        //return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function ChangePasswordUserRequest(
    payload: ChangePasswordRequest,
    url: string = changePasswordUrl
) {
    try {
        const response = await axios.post(url, payload, {
            headers: GetDefaultHeaders(),
        });

        console.log('Response:', response.data.data);
        //return response.data.data;
    } catch (error) {
        console.error('Error:', error);
    }
}
