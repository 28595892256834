export const LANGUAGES: { key: string; value: string }[] = [
    { key: 'English', value: 'English' },
    { key: 'Mandarin', value: 'Mandarin' },
    { key: 'Hindi', value: 'Hindi' },
    { key: 'Spanish', value: 'Spanish' },
    { key: 'French', value: 'French' },
    { key: 'Arabic', value: 'Arabic' },
    { key: 'Bengali', value: 'Bengali' },
    { key: 'Portuguese', value: 'Portuguese' },
    { key: 'Russian', value: 'Russian' },
    { key: 'Japanese', value: 'Japanese' },
    { key: 'Punjabi', value: 'Punjabi' },
    { key: 'German', value: 'German' },
    { key: 'Javanese', value: 'Javanese' },
    { key: 'Korean', value: 'Korean' },
    { key: 'Telugu', value: 'Telugu' },
    { key: 'Marathi', value: 'Marathi' },
    { key: 'Tamil', value: 'Tamil' },
    { key: 'Urdu', value: 'Urdu' },
    { key: 'Italian', value: 'Italian' },
    { key: 'Turkish', value: 'Turkish' },
    { key: 'Persian', value: 'Persian' },
    { key: 'Gujarati', value: 'Gujarati' },
    { key: 'Polish', value: 'Polish' },
    { key: 'Ukrainian', value: 'Ukrainian' },
];
