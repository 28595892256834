import React, { useState } from 'react';
import './Auth.css';
import login_img from '../../assets/login_img.png';
import { Link } from 'react-router-dom';
import { RegisterRequest } from '../../api/models';
import { getCastes, getReligions, registerUserRequest } from '../../api';
import { GENDER } from '@constants/GENDER';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadingStarted, loadingStopped } from '@store/IsLoading/reducer';
// import { RELIGION } from '@constants/RELIGION';
// import { SUB_CASTE } from '@constants/SUB_CASTE';
// import Datepicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { FaCalendarAlt } from 'react-icons/fa';

const Register: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState<RegisterRequest>({
        first_name: '',
        last_name: '',
        date_of_birth: null,
        gender: '',
        religion: '',
        caste: '',
        email_address: '',
        mobile: '',
        password: '',
        confirm_password: '',
    });

    const [religion, setReligions] = React.useState<
        Array<{
            _id: string;
            name: string;
        }>
    >();

    const [castes, setCastes] = React.useState<
        Array<{
            _id: string;
            name: string;
        }>
    >([]);

    const fetchCastes = async (id: string) => {
        dispatch(loadingStarted(true));
        const response = await getCastes({ religionId: id });
        setCastes(response);
        dispatch(loadingStopped());
    };

    React.useEffect(() => {
        if (!religion) {
            dispatch(loadingStarted(true));
            getReligions().then((res) => {
                setReligions(res);
                dispatch(loadingStopped());
            });
        }
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            dispatch(loadingStarted(true));
            await registerUserRequest(formData);
            dispatch(loadingStopped());
            navigate('/login');
        } catch (error) {
            console.error('Error:', error);
            if (error?.response?.data?.status === 'error') {
                alert(error?.response?.data?.message);
            }
            dispatch(loadingStopped());
        }
    };

    return (
        <div className="register-main">
            <div className="container">
                <div className="login-section">
                    <h2>Register</h2>
                    <form className="register" onSubmit={handleSubmit}>
                        <div className="input-group">
                            <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                placeholder="First Name"
                                value={formData.first_name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <input
                                type="text"
                                id="last_name"
                                name="last_name"
                                placeholder="Last Name"
                                value={formData.last_name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <input
                                type="date"
                                id="date_of_birth"
                                name="date_of_birth"
                                placeholder="DOB DD/MM/YYYY"
                                value={formData.date_of_birth}
                                onChange={handleInputChange}
                                required
                            />
                            {/* <Datepicker
                        selected={formData.date_of_birth}
                        id="date_of_birth"
                        name="date_of_birth"
                       // placeholder="DOB DD/MM/YYYY"
                        //value={formData.date_of_birth}
                        //onChange={handleInputChange}
                        onChange={date=>setFormData(date)}
                        required
                        /> */}
                        </div>
                        <div className="input-group">
                            <select
                                className="form-select"
                                name="gender"
                                value={formData.gender}
                                onChange={handleInputChange}
                            >
                                <option value={null}>Gender</option>
                                {GENDER.map((item) => (
                                    <option key={item.key} value={item.value}>
                                        {item.key}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="input-group">
                            <select
                                className="form-select"
                                name="religion"
                                value={formData.religion}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    fetchCastes(e?.target?.value);
                                }}
                            >
                                <option value={null}>Religion</option>
                                {religion?.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="input-group">
                            <select
                                className="form-select"
                                name="caste"
                                value={formData.caste}
                                onChange={handleInputChange}
                            >
                                <option value={null}>Community</option>
                                {castes?.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="input-group">
                            <input
                                type="text"
                                id="email_address"
                                name="email_address"
                                placeholder="Email"
                                value={formData.email_address}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <input
                                type="text"
                                id="mobile"
                                name="mobile"
                                placeholder="Phone Number"
                                value={formData.mobile}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <input
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <input
                                type="password"
                                id="confirm_password"
                                name="confirm_password"
                                placeholder="Confirm Password"
                                value={formData.confirm_password}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <button className="login" type="submit">
                            Register
                        </button>
                    </form>
                    <p className="mt-4">
                        <Link to="/login">
                            Already a Member?&nbsp;
                            <span className="text-bm-secondary">Login</span>
                        </Link>
                    </p>
                </div>
                <div className="image-section">
                    <img src={login_img} alt="Couple Image" />
                </div>
            </div>
        </div>
    );
};

export default Register;
