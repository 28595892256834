import React from 'react';
import { UserProfile } from './LikedProfile.model';
import MembersCard from './InnerComponents/MembersCard';
import Footer from '@components/Footer/Footer';
import Navbar from '@components/Navbar/Navbar';
import Banner from '@components/Banner/Banner';
import { getShortListedUser } from '../../api';
import MyProfileTabs from '@components/MyProfileTabs/MyProfileTabs';
import { useDispatch } from 'react-redux';
import { loadingStarted, loadingStopped } from '@store/IsLoading/reducer';

const LikedProfiles: React.FC = () => {
    const [profiles, setProfiles] = React.useState<Array<UserProfile>>();
    const [isLoaded, setIsLoaded] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!isLoaded) {
            dispatch(loadingStarted(true));
            getShortListedUser({ pageSize: 100, pageNumber: 1 }).then(
                (res: Array<UserProfile>) => {
                    setProfiles(res);
                    setIsLoaded(true);
                    dispatch(loadingStopped());
                }
            );
        }
    });

    return (
        <>
            {isLoaded ? (
                <div className="bg-white">
                    <Navbar />
                    <Banner title="My Liked Profiles" />
                    <MyProfileTabs activeTab="MyLikedProfiles"></MyProfileTabs>
                    <div className="mt-3 min-h-screen bg-white">
                        <div className="flex justify-center  liked">
                            <main className="bg-white w-3/4 p-4 grid grid-cols-1 gap-6 w-cus">
                                {profiles?.map((profile: UserProfile) => (
                                    <MembersCard
                                        key={profile.user_id}
                                        profile={profile}
                                    />
                                ))}
                            </main>
                        </div>

                        <Footer />
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default LikedProfiles;
