export const RELIGION: { key: string; value: string }[] = [
    { key: 'Christianity', value: 'Christianity' },
    { key: 'Islam', value: 'Islam' },
    { key: 'Hinduism', value: 'Hinduism' },
    { key: 'Buddhism', value: 'Buddhism' },
    { key: 'Sikhism', value: 'Sikhism' },
    { key: 'Judaism', value: 'Judaism' },
    { key: 'Bahai', value: "Bahá'í" },
    { key: 'Jainism', value: 'Jainism' },
    { key: 'Shinto', value: 'Shinto' },
    { key: 'Zoroastrianism', value: 'Zoroastrianism' },
    { key: 'Taoism', value: 'Taoism' },
    { key: 'Confucianism', value: 'Confucianism' },
    { key: 'Rastafarianism', value: 'Rastafarianism' },
    { key: 'Paganism', value: 'Paganism' },
    { key: 'Wicca', value: 'Wicca' },
    { key: 'Animism', value: 'Animism' },
    { key: 'Atheism', value: 'Atheism' },
    { key: 'Agnosticism', value: 'Agnosticism' },
    { key: 'Spiritism', value: 'Spiritism' },
    { key: 'CaoDai', value: 'Cao Đài' },
    { key: 'Tenrikyo', value: 'Tenrikyo' },
    { key: 'SeichoNoIe', value: 'Seicho-no-Ie' },
    { key: 'Deism', value: 'Deism' },
];
