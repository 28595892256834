import * as React from 'react';
// import brideImg from '@assets/images/best-tanks-tops-8 1.png';
import { connectRequest, getMembersProfile } from '../../api';
import { useDispatch } from 'react-redux';
import { loadingStarted, loadingStopped } from '@store/IsLoading/reducer';

const MemberProfile: React.FC<{ id: string }> = ({ id }) => {
    const [profileData, setProfile] = React.useState<UserProfile>();
    const dispatch = useDispatch();

    const fetchMembersProfile = async (userId: string) => {
        dispatch(loadingStarted(true));
        setProfile(await getMembersProfile(userId));
        dispatch(loadingStopped());
    };

    React.useEffect(() => {
        if (!profileData) {
            fetchMembersProfile(id);
        }
    });

    const sendConnectRequest = async () => {
        dispatch(loadingStarted(true));
        setProfile(await connectRequest(id));
        dispatch(loadingStopped());
    };

    /**
    const profileData = {
        _id: '66b8eba7ccba0bf13789dc5d',
        astro_details?: {
            date_of_birth: '2000-08-08T00:00:00.000Z',
            birth_time: '5:46 AM',
            place_of_birth: 'Delhi',
            rashi: 'ARIES (MESH)',
            name_as_rashi: 'Ansh',
            nakshatra: 'ASHVINI',
            gotra: 'Gehlot',
            is_manglik: 'No',
        },
        basic_info?: {
            first_name: 'Ashok',
            last_name: 'Mistra',
            age: 34,
            maritial_status: 'UNMARRIED',
            have_children: 'Yes, living together',
            no_of_children: '2',
            hobbies_intrest:
                'Gardening, Photography,Learning a Musical Instrument',
            about_me:
                "Hello! I'm Anil, and I'm excited to share a little bit about myself. I'm a 'graphic designer' with a passion for 'creativity'.",
            height: 5.9,
            mother_tongue: '66c19986a3c3bcad8448144f',
            qualification: 'MBA',
            occupation: 'ARCHITECT',
            annual_income: '14-16 Lakhs',
        },
        documents_photos?: {
            profile_image:
                'https://img.freepik.com/free-photo/porait-cute-boy-cafe_23-2148436119.jpgabcd.png',
        },
        education_occupation?: {
            qualification: 'MBA',
            occupation: 'ARCHITECT',
            annual_income: '14-16 Lakhs',
            employed_in: 'PRIVATE SECTOR',
            working_with_company: 'Google India Limited',
        },
        family_details?: {
            father_name: 'Ramesh Kumar',
            father_occupation: 'Business',
            mother_name: 'Prinka',
            mother_occupation: 'Service-Private',
            no_of_brothers: '2',
            married_brothers: '1',
            no_of_sisters: '1',
            married_sisters: '1',
            _id: '66d881387c54a8eea4b9058a',
        },
        partner_preference?: {
            height: {
                from: 5.1,
                to: 6.5,
                _id: '66d881387c54a8eea4b90586',
            },
            weight: {
                from: 65,
                to: 70,
                _id: '66d881387c54a8eea4b90587',
            },
            age: {
                from: 28,
                to: 35,
                _id: '66d881387c54a8eea4b90588',
            },
            qualification: 'BBA',
            salary_expectation: '14-16 Lakhs',
            maritial_status: 'UNMARRIED',
            have_children: 'Yes, living together',
            no_of_children: '2',
            hobbies_intrest:
                'Gardening, Photography,Learning a Musical Instrument',
            about_me:
                "Hello! I'm Anil, and I'm excited to share a little bit about myself. I'm a 'graphic designer' with a passion for 'creativity'.",
            body_type: 'SLIM',
            complexion: 'VERY FAIR',
            intercast: 'Yes',
            is_manglik: 'Yes',
            partner_expectaion_details:
                'Expect regular and honest communication about feelings, thoughts, and concerns,Seek someone who is dependable and keeps their promises,Desire a partner who respects your individuality, boundaries, and opinions',
            _id: '66d881387c54a8eea4b90585',
        },
        physical_attributes?: {
            height: 5.9,
            weight: 80,
            gender: 'Male',
            blood_group: 'A-negative (A-)',
            complexion: 'FAIR',
            body_type: 'AVERAGE',
            spectacles: 'No',
            smoke: 'Occasionally',
            drink: 'No',
            diet: 'VEG',
            physical_status: 'Disabled',
            disabled_reason:
                "I have a physical disability that affects 'my mobility', Loss of a limb due to injury, illness, or congenital condition",
            _id: '66d881387c54a8eea4b9058d',
        },
        religious_social_background?: {
            religion: 'HINDU',
            caste: 'NAIK MARATHA',
            caste_category: 'OPEN',
            mother_tongue: 'AWADHI',
            family_status: 'RICH',
            family_type: 'JOINT',
            _id: '66d881387c54a8eea4b9058b',
        },
        resedence_details?: {
            current_residence:
                'Home Plaza Complex, 3rd Floor, Colony Bazaar, AK Azad Rd, opp. Indusland Bank, Kala Pahar, Guwahati, Assam 781018, India',
            full_address:
                'RK Mission Rd, Bishnu Rabha Nagar, Birubari,  Guwahati, Assam 781016, India',
            pin_code: '781016',
            country: 'India',
            state: 'Assam',
            district: 'Kamrup Metropolitan',
            city: 'Guwahati',
            residence_status: 'FAMILY OWNED',
            _id: '66d881387c54a8eea4b90589',
        },
        contact_details?: {
            email_address: 'blissfulmatch.matrimonial@gmail.com',
            mobile: '8690415679',
            address:
                'RK Mission Rd, Bishnu Rabha Nagar, Birubari,  Guwahati, Assam 781016, India',
        },
    };
*/
    return (
        <>
            <div className="flex overflow-hidden flex-col bg-white  profile-main">
                <div className="flex flex-col items-start self-center mt-4 w-full max-w-[1400px] max-md:mt-10 max-md:max-w-full">
                    <div className="max-w-full w-[1000px] ">
                        <div className="flex gap-4 max-md:flex-col">
                            <div className="flex flex-col w-4/12 max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col grow justify-center px-3 py-1 w-full bg-gray-200 rounded-xl max-md:pr-5 max-md:mt-8 max-md:max-w-full">
                                    <img
                                        loading="lazy"
                                        src={
                                            profileData?.documents_photos
                                                ?.profile_image
                                        }
                                        className="pro-img-main object-contain w-full aspect-[0.68] max-md:max-w-full "
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col ml-3 w-8/12 max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col items-start mt-4 w-full text-lg text-red-950 max-md:mt-8 max-md:max-w-full fs-14">
                                    <div className="flex gap-2 max-w-full w-[280px]">
                                        <div className="flex flex-col font-bold">
                                            <div>NAME: </div>
                                            <div className="self-start mt-4 max-md:mt-6">
                                                AGE:
                                            </div>
                                        </div>
                                        <div className="flex flex-col font-medium">
                                            <div className="self-end">
                                                {
                                                    profileData?.basic_info
                                                        ?.first_name
                                                }
                                                &nbsp;
                                                {
                                                    profileData?.basic_info
                                                        ?.last_name
                                                }
                                            </div>
                                            <div className="self-start mt-4 max-md:mt-6">
                                                {profileData?.basic_info?.age}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-4 mt-4 whitespace-nowrap max-md:mt-6">
                                        <div className="grow font-bold">
                                            LOCATION:
                                        </div>
                                        <div className="font-medium basis-auto">
                                            {
                                                profileData?.contact_details
                                                    ?.address
                                            }
                                        </div>
                                    </div>
                                    <div className="flex gap-4 mt-4 whitespace-nowrap max-md:mt-6">
                                        <div className="font-bold basis-auto">
                                            RELIGION:
                                        </div>
                                        <div className="font-medium basis-auto">
                                            {
                                                profileData
                                                    ?.religious_social_background
                                                    ?.religion
                                            }
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap gap-4 mt-4 max-md:mt-6">
                                        <div className="flex-auto font-bold">
                                            MOTHER TONGUE:
                                        </div>
                                        <div className="font-medium basis-auto">
                                            {
                                                profileData
                                                    ?.religious_social_background
                                                    ?.mother_tongue
                                            }
                                        </div>
                                    </div>
                                    <div className="flex gap-2 mt-4 whitespace-nowrap max-md:mt-6">
                                        <div className="grow font-bold">
                                            HEIGHT:
                                        </div>
                                        <div className="font-medium">
                                            {profileData?.basic_info?.height}
                                        </div>
                                    </div>

                                    <div className="flex gap-2 mt-4 whitespace-nowrap max-md:mt-6">
                                        <div className="grow font-bold">
                                            Marital Status:
                                        </div>
                                        <div className="font-medium">
                                            {
                                                profileData?.basic_info
                                                    ?.maritial_status
                                            }
                                        </div>
                                    </div>

                                    <div className="flex gap-2 mt-4 whitespace-nowrap max-md:mt-6">
                                        <div className="grow font-bold">
                                            Education:
                                        </div>
                                        <div className="font-medium">
                                            {
                                                profileData
                                                    ?.education_occupation
                                                    ?.qualification
                                            }
                                        </div>
                                    </div>

                                    <div className="flex gap-2 mt-4 whitespace-nowrap max-md:mt-6">
                                        <div className="grow font-bold">
                                            Occupation:
                                        </div>
                                        <div className="font-medium">
                                            {
                                                profileData
                                                    ?.education_occupation
                                                    ?.occupation
                                            }
                                        </div>
                                    </div>

                                    <div className="flex gap-2 mt-4 whitespace-nowrap max-md:mt-6">
                                        <div className="grow font-bold">
                                            Annual Income:
                                        </div>
                                        <div className="font-medium">
                                            {
                                                profileData
                                                    ?.education_occupation
                                                    ?.annual_income
                                            }
                                        </div>
                                    </div>

                                    <div className="flex gap-2 mt-4 whitespace-nowrap max-md:mt-6">
                                        <div className="grow font-bold">
                                            Hobbies & Interest:
                                        </div>
                                        <div className="font-medium">
                                            {
                                                profileData?.basic_info
                                                    ?.hobbies_intrest
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 text-xl font-bold text-black max-md:mt-6 border-b-4 border-bm-secondary">
                        ABOUT:
                    </div>
                    <div className="self-stretch mt-8 text-lg text-black max-md:mt-6 max-md:mr-2.5 max-md:max-w-full">
                        {profileData?.basic_info?.about_me}
                    </div>
                    <button
                        onClick={sendConnectRequest}
                        className="self-stretch px-8 pt-2 pb-4 mt-8 text-xl font-medium text-center align-middle content-center rounded-lg text-white whitespace-nowrap bg-orange-400 max-md:px-2 max-md:mt-6 max-md:max-w-full"
                    >
                        CONNECT
                    </button>

                    <div className="mt-10 text-xl font-bold text-black max-md:mt-6 border-b-4 border-bm-secondary">
                        CONTACT DETAILS:
                    </div>
                    <div className="flex  flex-wrap gap-3 justify-between mt-8 max-w-full text-xl font-bold text-black w-[900px] max-md:mt-6 fs-14">
                        <div>
                            EMAIL:&nbsp;
                            <span className="">
                                {profileData?.contact_details?.email_address}
                            </span>
                        </div>
                        <div className="max-md:max-w-full">
                            PHONE:&nbsp;
                            <span className="">
                                {profileData?.contact_details?.mobile}
                            </span>
                        </div>
                        <div className="max-md:max-w-full">
                            ADDRESS:&nbsp;
                            <span className="">
                                {profileData?.contact_details?.address}
                            </span>
                        </div>
                    </div>

                    <div className="mt-10 text-xl font-bold text-black max-md:mt-6 max-md:max-w-full border-b-4 border-bm-secondary">
                        PHYSICAL ATTRIBUTES:
                    </div>
                    <div className="mt-10 w-full max-w-[1400px] max-md:mt-6 max-md:max-w-full">
                        <div className="flex gap-3 max-md:flex-col">
                            <div className="flex flex-col w-[30%] max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col grow text-xl font-bold text-black max-md:mt-6 max-md:max-w-full fs-14">
                                    <div className="max-md:max-w-full">
                                        <div className="self-start">
                                            WEIGHT : &nbsp;
                                            <span className="font-medium">
                                                {
                                                    profileData
                                                        ?.physical_attributes
                                                        ?.weight
                                                }
                                            </span>
                                        </div>
                                        <div className="self-start mt-8 max-md:mt-6">
                                            HEIGHT : &nbsp;
                                            <span className="font-medium">
                                                {
                                                    profileData
                                                        ?.physical_attributes
                                                        ?.height
                                                }
                                            </span>
                                        </div>
                                        <div className="self-start mt-8 max-md:mt-6">
                                            BLOOD GROUP : &nbsp;
                                            <span className="font-medium">
                                                {
                                                    profileData
                                                        ?.physical_attributes
                                                        ?.blood_group
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col ml-3 w-[30%] max-md:ml-0 max-md:w-full">
                                <div className="text-xl font-bold text-black max-md:mt-6 max-md:max-w-full fs-14">
                                    BODY TYPE:
                                    <span className="font-medium">
                                        {
                                            profileData?.physical_attributes
                                                ?.body_type
                                        }
                                    </span>
                                    <br />
                                    <br />
                                    COMPLEXION:&nbsp;
                                    <span className="font-medium">
                                        {
                                            profileData?.physical_attributes
                                                ?.complexion
                                        }
                                    </span>
                                    <br />
                                    <br />
                                    DRINK:
                                    <span className="font-medium">
                                        {
                                            profileData?.physical_attributes
                                                ?.drink
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col ml-3 w-[30%] max-md:ml-0 max-md:w-full">
                                <div className="text-xl font-bold text-black max-md:mt-6 max-md:max-w-full fs-14">
                                    SMOKE:{' '}
                                    <span className="font-medium">
                                        {
                                            profileData?.physical_attributes
                                                ?.smoke
                                        }
                                    </span>
                                    <br />
                                    <br />
                                    DIET:{' '}
                                    <span className="font-medium">
                                        {profileData?.physical_attributes?.diet}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-10 text-xl font-bold text-black max-md:mt-6 border-b-4 border-bm-secondary">
                        EDUCATION QUALIFICATION:
                    </div>
                    <div className="flex  flex-wrap gap-3 justify-between mt-8 max-w-full text-xl font-bold text-black w-[900px] max-md:mt-6 fs-14">
                        <div>
                            OCCUPATION: &nbsp;
                            <span className="">
                                {profileData?.education_occupation?.occupation}
                            </span>
                            <br />
                            <br />
                            ANNUAL INCOME: &nbsp;
                            <span className="">
                                {
                                    profileData?.education_occupation
                                        ?.annual_income
                                }
                            </span>
                            <br />
                            <br />
                        </div>
                        <div className="max-md:max-w-full">
                            EDUCATION: &nbsp;
                            <span className="">
                                {profileData?.physical_attributes?.diet}
                            </span>
                        </div>
                    </div>

                    <div className="mt-10 text-xl font-bold text-black max-md:mt-6 border-b-4 border-bm-secondary">
                        ASTROL DETAILS:
                    </div>
                    <div className="flex flex-wrap gap-3 justify-between mt-8 max-w-full text-xl font-bold text-black w-[900px] max-md:mt-6 fs-14">
                        <div>
                            DATE OF BIRTH: &nbsp;
                            <span className="">
                                {profileData?.astro_details?.date_of_birth}
                            </span>
                            <br />
                            <br />
                            BIRTH TIME: &nbsp;
                            <span className="">
                                {profileData?.astro_details?.birth_time}
                            </span>
                            <br />
                            <br />
                            IS MANGLIK: &nbsp;
                            <span className="">
                                {profileData?.astro_details?.is_manglik}
                            </span>
                            <br />
                            <br />
                        </div>
                        <div className="max-md:max-w-full">
                            PLACE OF BIRTH:{' '}
                            <span className="">
                                {profileData?.astro_details?.place_of_birth}
                            </span>
                            <br />
                            <br />
                            Sun Sign:{' '}
                            <span className="">
                                {profileData?.astro_details?.rashi}
                            </span>
                            <br />
                            <br />
                        </div>
                    </div>

                    <div className="mt-10 text-xl font-bold text-black max-md:mt-6 border-b-4 border-bm-secondary">
                        RELIGIOUS AND SOCIAL BACKGROUND:
                    </div>
                    <div className="flex  flex-wrap gap-3 justify-between mt-8 max-w-full text-xl font-bold text-black w-[900px] max-md:mt-6 fs-14">
                        <div>
                            RELIGION:{' '}
                            <span className="">
                                {
                                    profileData?.religious_social_background
                                        ?.religion
                                }
                            </span>
                            <br />
                            <br />
                            CASTE:{' '}
                            <span className="">
                                {
                                    profileData?.religious_social_background
                                        ?.caste
                                }
                            </span>
                            <br />
                            <br />
                        </div>
                        <div className="max-md:max-w-full">
                            FAMILY STATUS:{' '}
                            <span className="">
                                {
                                    profileData?.religious_social_background
                                        ?.family_status
                                }
                            </span>
                            <br />
                            <br />
                            FAMILY TYPE:{' '}
                            <span className="">
                                {
                                    profileData?.religious_social_background
                                        ?.family_type
                                }
                            </span>
                            <br />
                            <br />
                        </div>
                    </div>

                    <div className="mt-10 text-xl font-bold text-black max-md:mt-6 border-b-4 border-bm-secondary">
                        FAMILY DETAILS:
                    </div>
                    <div className="flex  flex-wrap gap-3 justify-between mt-8 max-w-full text-xl font-bold text-black w-[900px] max-md:mt-6 fs-14">
                        <div>
                            FATHER's NAME:{' '}
                            <span className="">
                                {profileData?.family_details?.father_name}
                            </span>
                            <br />
                            <br />
                            FATHER's OCCUPATION:{' '}
                            <span className="">
                                {profileData?.family_details?.father_occupation}
                            </span>
                            <br />
                            <br />
                        </div>
                        <div className="max-md:max-w-full">
                            MOTHER's NAME:{' '}
                            <span className="">
                                {profileData?.family_details?.mother_name}
                            </span>
                            <br />
                            <br />
                            MOTHER's OCCUPATION:{' '}
                            <span className="">
                                {profileData?.family_details?.mother_occupation}
                            </span>
                            <br />
                            <br />
                        </div>
                    </div>

                    <div className="mt-10 text-xl font-bold text-black max-md:mt-6 border-b-4 border-bm-secondary">
                        RESIDENCE DETAILS:
                    </div>
                    <div className="flex  flex-wrap gap-3 justify-between mt-8 max-w-full text-xl font-bold text-black w-[900px] max-md:mt-6 fs-14">
                        <div>
                            CURRENT RESIDENCE:{' '}
                            <span className="">
                                {
                                    profileData?.resedence_details
                                        ?.current_residence
                                }
                            </span>
                            <br />
                            <br />
                            FULL ADDRESS:{' '}
                            <span className="">
                                {profileData?.resedence_details?.full_address}
                            </span>
                            <br />
                            <br />
                        </div>
                        <div>
                            COUNTRY:{' '}
                            <span className="">
                                {profileData?.resedence_details?.country}
                            </span>
                            <br />
                            <br />
                            STATE:{' '}
                            <span className="">
                                {profileData?.resedence_details?.state}
                            </span>
                            <br />
                            <br />
                            CITY:{' '}
                            <span className="">
                                {profileData?.resedence_details?.city}
                            </span>
                            <br />
                            <br />
                            DISTRICT:{' '}
                            <span className="">
                                {profileData?.resedence_details?.district}
                            </span>
                            <br />
                            <br />
                        </div>
                        <div className="max-md:max-w-full">
                            PIN CODE:{' '}
                            <span className="">
                                {profileData?.resedence_details?.pin_code}
                            </span>
                            <br />
                            <br />
                            RESIDENCE STATUS:{' '}
                            <span className="">
                                {
                                    profileData?.resedence_details
                                        ?.residence_status
                                }
                            </span>
                            <br />
                            <br />
                        </div>
                    </div>

                    <div className="mt-10 text-xl font-bold text-black max-md:mt-6 max-md:max-w-full border-b-4 border-bm-secondary">
                        PARTNER PREFERENCE:
                    </div>
                    <div className="mt-10 w-full max-w-[1400px] max-md:mt-6 max-md:max-w-full">
                        <div className="flex gap-3 max-md:flex-col">
                            <div className="flex flex-col w-[30%] max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col grow text-xl font-bold text-black max-md:mt-6 max-md:max-w-full fs-14">
                                    <div className="max-md:max-w-full">
                                        <div className="self-start">
                                            WEIGHT :{' '}
                                            <span className="font-medium">
                                                {
                                                    profileData
                                                        ?.partner_preference
                                                        ?.weight.from
                                                }
                                                -
                                                {
                                                    profileData
                                                        ?.partner_preference
                                                        ?.weight.to
                                                }
                                            </span>
                                        </div>
                                        <div className="self-start mt-8 max-md:mt-6">
                                            HEIGHT :{' '}
                                            <span className="font-medium">
                                                {
                                                    profileData
                                                        ?.partner_preference
                                                        ?.height.from
                                                }
                                                -
                                                {
                                                    profileData
                                                        ?.partner_preference
                                                        ?.height.to
                                                }
                                            </span>
                                        </div>
                                        <div className="self-start mt-8 max-md:mt-6">
                                            Age :{' '}
                                            <span className="font-medium">
                                                {
                                                    profileData
                                                        ?.partner_preference
                                                        ?.age.from
                                                }
                                                -
                                                {
                                                    profileData
                                                        ?.partner_preference
                                                        ?.age.to
                                                }
                                            </span>
                                        </div>
                                        <div className="self-start mt-8 max-md:mt-6">
                                            MARITAL STATUS :
                                            <span className="font-medium">
                                                {
                                                    profileData
                                                        ?.partner_preference
                                                        ?.maritial_status
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mt-6 mr-2 max-md:mr-2.5 max-md:max-w-full">
                                        CHILDREN PREFERENCE:{' '}
                                        <span className="font-medium">
                                            {
                                                profileData?.partner_preference
                                                    ?.no_of_children
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col ml-3 w-[30%] max-md:ml-0 max-md:w-full">
                                <div className="text-xl font-bold text-black max-md:mt-6 max-md:max-w-full fs-14">
                                    INTER CASTE:
                                    <span className="font-medium">
                                        {
                                            profileData?.partner_preference
                                                ?.intercast
                                        }
                                    </span>
                                    <br />
                                    <br />
                                    SALARY EXPECTATION:{' '}
                                    <span className="font-medium">
                                        {
                                            profileData?.partner_preference
                                                ?.salary_expectation
                                        }
                                    </span>
                                    <br />
                                    <br />
                                    EDUCATION:
                                    <span className="font-medium">
                                        {
                                            profileData?.partner_preference
                                                ?.qualification
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col ml-3 w-[30%] max-md:ml-0 max-md:w-full">
                                <div className="text-xl font-bold text-black max-md:mt-6 max-md:max-w-full fs-14">
                                    BODY TYPE:{' '}
                                    <span className="font-medium">
                                        {
                                            profileData?.partner_preference
                                                ?.body_type
                                        }
                                    </span>
                                    <br />
                                    <br />
                                    COMPLEXION:{' '}
                                    <span className="font-medium">
                                        {
                                            profileData?.partner_preference
                                                ?.complexion
                                        }
                                    </span>
                                    <br />
                                    <br />
                                    MANGAL:{' '}
                                    <span className="font-medium">
                                        {
                                            profileData?.partner_preference
                                                ?.is_manglik
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 text-xl font-bold text-black max-md:mt-6 max-md:max-w-full mb-10 fs-14">
                        PARTNER EXPECTATION IN DETAIL :{' '}
                        <span className="font-medium">
                            {
                                profileData?.partner_preference
                                    ?.partner_expectaion_details
                            }
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MemberProfile;

interface AstroDetails {
    date_of_birth: string; // ISO format date string
    birth_time: string;
    place_of_birth: string;
    rashi: string;
    name_as_rashi: string;
    nakshatra: string;
    gotra: string;
    is_manglik: string;
}

interface BasicInfo {
    first_name: string;
    last_name: string;
    age: number;
    maritial_status: string;
    have_children: string;
    no_of_children: string;
    hobbies_intrest: string;
    about_me: string;
    height: number;
    mother_tongue: string;
    qualification: string;
    occupation: string;
    annual_income: string;
}

interface DocumentsPhotos {
    profile_image: string;
}

interface EducationOccupation {
    qualification: string;
    occupation: string;
    annual_income: string;
    employed_in: string;
    working_with_company: string;
}

interface FamilyDetails {
    father_name: string;
    father_occupation: string;
    mother_name: string;
    mother_occupation: string;
    no_of_brothers: string;
    married_brothers: string;
    no_of_sisters: string;
    married_sisters: string;
    _id: string;
}

interface PartnerPreference {
    height: {
        from: number;
        to: number;
        _id: string;
    };
    weight: {
        from: number;
        to: number;
        _id: string;
    };
    age: {
        from: number;
        to: number;
        _id: string;
    };
    qualification: string;
    salary_expectation: string;
    maritial_status: string;
    have_children: string;
    no_of_children: string;
    hobbies_intrest: string;
    about_me: string;
    body_type: string;
    complexion: string;
    intercast: string;
    is_manglik: string;
    partner_expectaion_details: string;
    _id: string;
}

interface PhysicalAttributes {
    height: number;
    weight: number;
    gender: string;
    blood_group: string;
    complexion: string;
    body_type: string;
    spectacles: string;
    smoke: string;
    drink: string;
    diet: string;
    physical_status: string;
    disabled_reason: string;
    _id: string;
}

interface ReligiousSocialBackground {
    religion: string;
    caste: string;
    caste_category: string;
    mother_tongue: string;
    family_status: string;
    family_type: string;
    _id: string;
}

interface ResidenceDetails {
    current_residence: string;
    full_address: string;
    pin_code: string;
    country: string;
    state: string;
    district: string;
    city: string;
    residence_status: string;
    _id: string;
}

interface ContactDetails {
    email_address: string;
    mobile: string;
    address: string;
}

interface UserProfile {
    _id: string;
    astro_details?: AstroDetails | undefined;
    basic_info?: BasicInfo | undefined;
    documents_photos?: DocumentsPhotos | undefined;
    education_occupation?: EducationOccupation | undefined;
    family_details?: FamilyDetails | undefined;
    partner_preference?: PartnerPreference | undefined;
    physical_attributes?: PhysicalAttributes | undefined;
    religious_social_background?: ReligiousSocialBackground | undefined;
    resedence_details?: ResidenceDetails | undefined;
    contact_details?: ContactDetails | undefined;
}
