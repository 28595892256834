import React, { useState } from 'react';
import './Auth.css';
import login_img from '../../assets/login_img.png';
import { ChangePasswordUserRequest } from '../../api';
import { ChangePasswordRequest } from '../../api/models';

const ChangePassword = () => {
    const [formData, setFormData] = useState<ChangePasswordRequest>({
        current_password: '',
        new_password: '',
        confirm_password: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await ChangePasswordUserRequest(formData);
            console.log('Response:', response);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container">
            <div className="login-section">
                <h2>Change Password</h2>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <input
                            type="password"
                            id="current_password"
                            name="current_password"
                            placeholder="Current Password"
                            value={formData.current_password}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="password"
                            id="new_password"
                            name="new_password"
                            placeholder="New Password"
                            value={formData.new_password}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="password"
                            id="confirm_password"
                            name="confirm_password"
                            placeholder="Confirm Password"
                            value={formData.confirm_password}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <button className="login" type="submit">
                        Update Password
                    </button>
                </form>
            </div>
            <div className="image-section">
                <img src={login_img} alt="Couple Image" />
            </div>
        </div>
    );
};

export default ChangePassword;
