import React from 'react';
import redHeart from '@assets/svg/RedHeart.svg';
import grayHeart from '@assets/svg/GrayHeart.svg';
import { UserProfile } from '../ViewHistory.models';
import { NavLink } from 'react-router-dom';
import { addToShortList, removeFromShortList } from '../../../api';

const MembersCard: React.FC<{ profile: UserProfile }> = ({
    profile,
}: {
    profile: UserProfile;
}) => {
    const [profileState, setProfile] = React.useState(profile);

    const likeUnlike = () => {
        if (profileState.isShortListed) {
            removeFromShortList({
                receiverId: profileState.user_id,
            });
        } else {
            addToShortList({
                receiverId: profileState.user_id,
            });
        }

        setProfile((oldData) => ({
            ...oldData,
            isShortListed: !oldData.isShortListed,
        }));
    };
    return (
        <div
            key={profileState.first_name}
            className="grid grid-cols-4 h-fit gap-3  my-history"
        >
            <div className="w-auto bg-bm-gray  rounded-lg col-span-1 h-fit ">
                <div className="m-2 flex flex-col items-center">
                    <div className="w-11/12  overflow-hidden grid relative">
                        <img
                            src={profileState.profile_image}
                            alt={profileState.first_name}
                            className="justify-self-center img-hist"
                        />
                        <button
                            type="button"
                            className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center bg-white rounded-full shadow-md hover:bg-gray-100"
                            onClick={likeUnlike}
                        >
                            <img
                                src={
                                    profileState.isShortListed
                                        ? redHeart
                                        : grayHeart
                                }
                                alt="like"
                                className="w-5 h-5"
                            />
                        </button>
                    </div>
                    <div className="w-full mt-2 text-center">
                        <p className="text-center">
                            {profileState.first_name} {profileState.last_name}
                        </p>
                        <p className="text-center">{profileState.location}</p>
                    </div>
                    <button className="mt-4 w-11/12 bg-gray-400 text-white py-2 rounded-lg">
                        <NavLink to={`/member-profile/${profileState.user_id}`}>
                            View Profile
                        </NavLink>
                    </button>
                </div>
            </div>

            <div className="bg-bm-gray  rounded-lg col-span-3 p-7 h-fit  my-history-description">
                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-black">
                        <tbody>
                            <tr className="border-gray-200">
                                <td
                                    scope="row"
                                    className="px-6 py-2 font-medium text-2xl col-span-2"
                                >
                                    INFORMATION
                                </td>
                            </tr>
                            <tr className="border-gray-200">
                                <td
                                    scope="row"
                                    className="px-6 py-2 font-medium"
                                >
                                    <p className="font-bold text-xl">Email</p>
                                    <p>{profileState.email_address}</p>
                                </td>
                                <td
                                    scope="row"
                                    className="px-6 py-2 font-medium"
                                >
                                    <p className="font-bold text-xl">Phone</p>
                                    <p>{profileState.mobile}</p>
                                </td>
                            </tr>
                            <tr className="border-gray-200 dark:border-gray-700">
                                <td
                                    scope="row"
                                    className="px-6 py-2"
                                    colSpan={2}
                                >
                                    <p className="font-bold text-xl">ABOUT</p>
                                    <p className="text-justify">
                                        {profileState.about_me}
                                    </p>
                                </td>
                            </tr>
                            <tr className="border-gray-200 dark:border-gray-700">
                                <td
                                    scope="row"
                                    className="px-6 py-2 font-medium"
                                >
                                    <p className="font-bold text-xl">
                                        VIEWED AT
                                    </p>
                                    <p>
                                        Date:{' '}
                                        {profileState.viewed_at_date.toString()}
                                    </p>
                                </td>
                                <td className="px-6 py-2">
                                    <p>&nbsp;</p>
                                    <p>Time: {profileState.viewed_at_time}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default MembersCard;
