import React, { useState } from 'react';
import './Contact.css';
import login_img from '../../assets/login_img.png';
import { ContactUsUserRequest } from '../../api';
import { ContactUsRequest } from '../../api/models';
import { useDispatch } from 'react-redux';
import { loadingStarted, loadingStopped } from '@store/IsLoading/reducer';
//import { useNavigate } from 'react-router-dom';

const Contact = () => {
    //const navigate = useNavigate();
    const [formData, setFormData] = useState<ContactUsRequest>({
        name: '',
        email: '',
        content: '',
    });
    const dispatch = useDispatch();
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            dispatch(loadingStarted(true));
            await ContactUsUserRequest(formData);
            dispatch(loadingStopped());
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container">
            <div className="login-section">
                <h2>Get in touch</h2>
                <div className="border-header"></div>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        {/* <label htmlFor="input">Name</label> */}
                        <input
                            type="input"
                            id="name"
                            name="name"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        {/* <label htmlFor="email">Email</label> */}
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        {/* <label>Content</label> */}
                        <textarea
                            placeholder="Content"
                            id="content"
                            name="content"
                            value={formData.content}
                            onChange={handleInputChange}
                        ></textarea>
                    </div>
                    <button className="contact" type="submit">
                        Submit
                    </button>
                </form>
                <p className="signup">
                    <input type="checkbox" id="newsletter" /> I would like to
                    recieve the newsletter.
                </p>
            </div>
            <div className="image-section">
                <img src={login_img} alt="Couple Image" />
            </div>
        </div>
    );
};

export default Contact;
